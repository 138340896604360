<template>
  <table class="table stock-control-table" v-if="!loading">
    <tbody>
      <tr
        v-for="item in menuStock"
        :key="item.id"
        class="item-row"
        :class="{ 'out-of-stock': item.stockControl != 'Available' }">
        <td
          class="item-name"
          :class="{ 'out-of-stock': item.stockControl != 'Available' }">
          {{ item.name }}
        </td>
        <td>
          <div class="stock-control-buttons" v-if="!item.saving">
            <IconButtonWithTip
              :active="item.stockControl === 'Available'"
              className="in-stock"
              :iconCode="mdiCheckCircle"
              tipText="Available"
              :disabled="unsavedChanges"
              :onclick="() => this.updateStockControl(item, 'Available')"
              :showTip="item.show === 'Available'" />
            <IconButtonWithTip
              :active="item.stockControl === 'ServiceBlocked'"
              className="out-of-stock"
              :iconCode="mdiCalendarRemove"
              :showTip="item.show === 'ServiceBlocked'"
              tipText="Service Blocked"
              :disabled="unsavedChanges"
              :onclick="
                () => this.updateStockControl(item, 'ServiceBlocked')
              " />
            <IconButtonWithTip
              :active="item.stockControl === 'ItemBlocked'"
              className="out-of-stock"
              :iconCode="mdiMinusCircle"
              :showTip="item.show === 'ItemBlocked'"
              tipText="Item Blocked"
              :disabled="unsavedChanges"
              :onclick="() => this.updateStockControl(item, 'ItemBlocked')" />
          </div>
          <div v-else class="center saving-indicator">
            <LoadingSpinner />Saving...
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no-data-wrapper" v-if="!loading && menuStock.length < 1">
    <p v-if="errorText">{{ errorText }}</p>
    <NoDataWrap v-else>No items in this menu</NoDataWrap>
  </div>
  <div v-if="loading" class="center loading-wrapper">
    <LoadingSpinner />
  </div>
</template>

<script>
  import store from "@/store";
  import IconButtonWithTip from "@/components/IconButtonWithTip";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import { mapGetters } from "vuex";
  import { mdiCheckCircle, mdiCalendarRemove, mdiMinusCircle } from "@mdi/js";

  export default {
    data() {
      return {
        serviceStock: [],
        menuStock: [],
        loading: true,
        errorText: "",
        mdiCheckCircle,
        mdiCalendarRemove,
        mdiMinusCircle,
      };
    },
    props: {
      service: {
        type: Object,
        default: null,
      },
      selectedMenu: {
        type: Object,
        default: null,
      },
      unsavedChanges: {
        type: Boolean,
        default: true,
      },
      loadStockList: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      IconButtonWithTip,
      LoadingSpinner,
      NoDataWrap,
    },
    computed: {
      ...mapGetters({
        apiPrivateMenusGetById: "apiPrivateMenus/getById",
      }),
    },
    watch: {
      loadStockList: function () {
        if (this.loadStockList) this.getItemList();
      },
    },
    methods: {
      // LOOK: Previously getAlterationsList was calling getItemsAlterationsId, which took in a menuId
      // This was then getting the menu from the repo, finding all of the alterations on it, and donwloading them one by one, including duplicates between items
      // The kitchen stock method already returns them all in one, so this isn't needed.

      // async getMenuItemId(menuId) {
      //   const menu = await this.apiPrivateMenusGetById(menuId);
      //   const itemsObjArray = menu.sections.map(({ items }) => items);
      //   const itemsId = itemsObjArray
      //     .reduce((result, item) => result.concat(item), [])
      //     .map(({ id }) => id);
      //   return itemsId;
      // },

      async getItemList() {
        this.loading = true;
        await store.state.apiPrivate.client.endpoints.kitchenStockItems
          .getAll(this.service.id)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              this.menuStock = [];
              if (response.data?.data?.length > 0) {
                response.data.data.forEach((item) =>
                  this.menuStock.push({ ...item, saving: false })
                );
              }
              this.loading = false;
            } else {
              this.errorText = "Something went wrong, Please contact Support.";
            }
          });
        this.loading = false;
      },

      updateStockControl(item, selectedValue) {
        item.saving = true;

        window.log.info(
          `[📦] Stock control for item ${item.name} [${item.id}] set to ${selectedValue}.`
        );

        store.state.apiPrivate.client.endpoints.kitchenStockItems
          .update(selectedValue, this.service.id, item.id)
          .then((response) => {
            item.saving = false;
            item["show"] = selectedValue;

            if (response.status >= 200 && response.status <= 204) {
              return;
            }

            return Promise.reject("Failed to update stock.");
          })
          .then(() => {
            item.stockControl = selectedValue;
          })
          .catch((err) => {
            window.log.error(err);
            this.getItemList();
          });
      },
    },
    mounted() {
      this.getItemList();
    },
  };
</script>

<style lang="scss" scoped>
  .item-row {
    cursor: pointer;
    &.out-of-stock {
      background: #fff4f4;
    }
  }
  .item-name {
    width: 60%;
    min-width: 100px;
    font-size: 1rem;
    cursor: default;

    &.out-of-stock {
      color: rgb(146, 22, 49);
    }
  }

  .stock-control-table {
    thead th {
      background: #fff;
      padding: 0.45rem 0.25rem;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    th,
    td {
      font-weight: 400;
      border: none !important;
      cursor: auto;
      padding: 0.5rem 0.15rem;
    }

    th:first-child,
    td:first-child {
      width: calc(100% - 120px);
      min-width: 80px;
      padding-left: 1rem;
      font-size: 0.875rem;
    }
    th:last-child {
      padding-left: 10px;
    }
    td:last-child {
      padding-right: 1rem;
    }
  }

  .stock-control-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .saving-indicator {
    color: $col_alpha;
    height: 40px;
    display: inline-flex;
    align-items: center;
    .loading-spinner {
      margin-right: 3px;
      border-width: 3px;
      width: 1rem;
      height: 1rem;
    }
  }

  .loading-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
  }

  .no-data-wrapper {
    min-height: 200px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
</style>
