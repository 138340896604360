<template>
  <table class="table stock-control-table" v-if="!loading">
    <tbody>
      <tr
        v-for="component in menuStock"
        :key="component.id"
        class="alteration-row"
        :class="{ 'out-of-stock': component.stockControl != 'Available' }">
        <td
          class="alteration-name"
          :class="{ 'out-of-stock': component.stockControl != 'Available' }">
          {{ component.name }}
        </td>
        <td>
          <div class="stock-control-buttons" v-if="!component.saving">
            <IconButtonWithTip
              :active="component.stockControl === 'Available'"
              className="in-stock"
              :iconCode="mdiCheckCircle"
              tipText="Available"
              :disabled="unsavedChanges"
              :onclick="() => this.updateStockControl(component, 'Available')"
              :showTip="component.show === 'Available'" />
            <IconButtonWithTip
              :active="component.stockControl === 'ServiceBlocked'"
              className="out-of-stock"
              :iconCode="mdiCalendarRemove"
              tipText="Service Blocked"
              :disabled="unsavedChanges"
              :onclick="
                () => this.updateStockControl(component, 'ServiceBlocked')
              "
              :showTip="component.show === 'ServiceBlocked'" />
            <IconButtonWithTip
              :active="component.stockControl === 'ComponentBlocked'"
              className="out-of-stock"
              :iconCode="mdiMinusCircle"
              tipText="Component Blocked"
              :disabled="unsavedChanges"
              :onclick="
                () => this.updateStockControl(component, 'ComponentBlocked')
              "
              :showTip="component.show === 'ComponentBlocked'" />
          </div>
          <div v-else class="center saving-indicator">
            <LoadingSpinner /> Saving...
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no-data-wrapper" v-if="!loading && menuStock.length < 1">
    <p v-if="errorText">{{ errorText }}</p>
    <NoDataWrap v-else>No components in this menu</NoDataWrap>
  </div>
  <div v-if="loading" class="center loading-wrapper">
    <LoadingSpinner />
  </div>
</template>

<script>
  import store from "@/store";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import IconButtonWithTip from "@/components/IconButtonWithTip";
  import { mapGetters } from "vuex";
  import { mdiCheckCircle, mdiCalendarRemove, mdiMinusCircle } from "@mdi/js";

  export default {
    data() {
      return {
        serviceStock: [],
        menuStock: [],
        loading: true,
        errorText: "",
        mdiCheckCircle,
        mdiCalendarRemove,
        mdiMinusCircle,
      };
    },
    props: {
      service: {
        type: Object,
        default: null,
      },
      selectedMenu: {
        type: Object,
        default: null,
      },
      unsavedChanges: {
        type: Boolean,
        default: true,
      },
      loadStockList: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        apiPrivateMenusGetById: "apiPrivateMenus/getById",
      }),
    },
    watch: {
      loadStockList: function () {
        if (this.loadStockList) this.getComponentList();
      },
    },
    methods: {
      async getComponentList() {
        this.loading = true;

        await store.state.apiPrivate.client.endpoints.kitchenStockComponents
          .getAll(this.service.id)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              this.menuStock = [];
              if (response.data?.data?.length > 0) {
                response.data.data.forEach((item) =>
                  this.menuStock.push({ ...item, saving: false })
                );
              }
              this.loading = false;
            } else {
              this.errorText = "Something went wrong, Please contact Support.";
            }
          });

        this.loading = false;
      },

      updateStockControl(component, selectedValue) {
        component.saving = true;
        window.log.info(
          `[📦] Stock control for component ${component.name} [${component.id}] set to ${selectedValue}.`
        );

        store.state.apiPrivate.client.endpoints.kitchenStockComponents
          .update(selectedValue, this.service.id, component.id)
          .then((response) => {
            component.saving = false;
            component["show"] = selectedValue;

            if (response.status >= 200 && response.status <= 204) {
              return;
            }

            return Promise.reject("Failed to update stock.");
          })
          .then(() => {
            component.stockControl = selectedValue;
          })
          .catch((err) => {
            window.log.error(err);
            this.getComponentList();
          });
      },
    },

    mounted() {
      this.getComponentList();
    },

    components: {
      NoDataWrap,
      IconButtonWithTip,
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    padding: 0 10px;
  }
  .product-name {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      background: #eee;
    }
  }

  .edit-table {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
  }

  .editable-value {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .alteration-row {
    cursor: pointer;
    &.out-of-stock {
      background: #ffeded;
    }
  }
  .alteration-name {
    width: 60%;
    min-width: 100px;
    font-size: 1rem;
    cursor: default;

    &.out-of-stock {
      color: rgb(146, 22, 49);
    }
  }

  .stock-control-table {
    thead th {
      background: #fff;
      padding: 0.45rem 0.25rem;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    th,
    td {
      font-weight: 400;
      border: none !important;
      cursor: auto;
      padding: 0.5rem 0.15rem;
    }

    th:first-child,
    td:first-child {
      width: calc(100% - 120px);
      min-width: 80px;
      padding-left: 1rem;
      font-size: 0.875rem;
    }
    th:last-child {
      padding-left: 10px;
    }
    td:last-child {
      padding-right: 1rem;
    }
  }

  .saving-indicator {
    color: $col_alpha;
    height: 40px;
    display: inline-flex;
    align-items: center;
    .loading-spinner {
      margin-right: 3px;
      border-width: 3px;
      width: 1rem;
      height: 1rem;
    }
  }

  .stock-control-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .loading-wrapper,
  .no-data-wrapper {
    min-height: 200px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
</style>
