<template>
  <div class="stock-icon-info">
    <p class="in-stock">
      <span class="material-icons material-icons-round">
        <Icon :path="mdiCheckCircle" :size="14"
      /></span>
      <span class="stock-info-label">Available</span>
    </p>
    <p class="out-of-stock">
      <span class="material-icons material-icons-round"
        ><Icon :path="mdiCalendarRemove" :size="14"
      /></span>
      <span class="stock-info-label">Blocked for this Service</span>
    </p>
    <p class="out-of-stock">
      <span class="material-icons material-icons-round"
        ><Icon :path="mdiMinusCircle" :size="14"
      /></span>
      <span class="stock-info-label">Blocked for all Services</span>
    </p>
  </div>
</template>

<script>
  import { mdiCheckCircle, mdiCalendarRemove, mdiMinusCircle } from "@mdi/js";

  export default {
    data() {
      return {
        mdiCheckCircle,
        mdiCalendarRemove,
        mdiMinusCircle,
      };
    },
    props: {
      itemOrComponent: {
        type: String,
        default: "item",
      },
    },
  };
</script>

<style lang="scss" scoped>
  .stock-icon-info {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    & > p {
      display: flex;
      flex-direction: row;
      gap: 2px;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
      span.stock-info-label {
        font-size: 0.75rem;
        color: #666;
      }
    }

    span.material-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }

    .in-stock {
      span.material-icons {
        color: #32b98b;
      }
    }

    .out-of-stock {
      span.material-icons {
        color: $col_delta-lighter;
      }
    }
  }
</style>
