<template>
  <div class="picker">
    <label v-if="resource != null" class="picker-value">
      <span class="name ellipsis">{{ resource.name }} </span>
      <span class="version" v-if="resource.version != null">
        (v{{ resource.version }})
      </span>
    </label>

    <label v-else class="picker-value">Please select</label>

    <button
      v-if="hasEditFunc"
      class="material-icons material-icons-pen"
      @click="$emit('onEditFunc')">
      edit
    </button>
    <button @click="$emit('onPickFunc')">
      <Icon :path="mdiAdjust" :size="18" />
    </button>
  </div>
</template>

<script>
  import { mdiAdjust } from "@mdi/js";

  export default {
    data() {
      return {
        mdiAdjust,
      };
    },
    props: {
      resource: {
        default: null,
        required: true,
      },
      hasEditFunc: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .picker {
    label.picker-value {
      padding-left: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.15rem;
      line-height: 34px;
      height: 34px;
    }
    .name {
      max-width: 110px;
      display: inline-block;
      line-height: 1em;
      font-size: 0.8rem;
    }
    .version {
      font-size: 0.75rem;
      margin: 2px 5px;
    }
    button {
      height: 100%;
      display: block;
    }
  }
</style>
