<template>
  <div class="main-content">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading service detail...</p>
    </div>
    <div v-else>
      <div class="fixed-header column">
        <BackToPageLink
          :title="goBackToPageData.title"
          @close="goToPage(goBackToPageData.path)" />
      </div>
      <Card
        flexDirection="column"
        :flex="12"
        className="full baseline service-detail-config mb_1">
        <template v-slot:content>
          <div class="service-details">
            <div class="row">
              <div class="configs row">
                <DetailConfigLabelAndInput label="Venue" :value="venue?.name" />
                <DetailConfigLabelAndInput
                  label="Outlet"
                  :value="outlet?.name" />
                <DetailConfigLabelAndInput
                  label="Service Date"
                  :value="service.startDate ?? service.date" />
                
                <DetailConfigLabelAndInput label="Menu" :useSlot="true">
                  <Picker
                    :resource="selectedMenu"
                    @onPickFunc="showModal = 'menuPicker'" />
                </DetailConfigLabelAndInput>

                <DetailConfigLabelAndInput
                  label="Start Time"
                  :editable="true"
                  name="startTime"
                  id="startTime"
                  v-model="serviceEdit.startTime"
                  @change="this.unsavedChanges = true" />

                <DetailConfigLabelAndInput
                  label="End Time"
                  :editable="true"
                  name="endTime"
                  id="endTime"
                  max="23:59"
                  v-model="serviceEdit.endTime"
                  @change="this.unsavedChanges = true"
                  :error="serviceEdit.endTime < serviceEdit.startTime ? 'The end time must be later than the start time': null" />

                <DetailConfigLabelAndInput label="Status" :useSlot="true">
                  <ServiceStatus
                    :isSealed="service.sealed"
                    :status="service.status"
                    @viewStatement="viewStatement()" />
                </DetailConfigLabelAndInput>
              </div>
              <div class="detail-links row">
                <span class="detail-label">View In:</span>
                <div class="row">
                  <ViewInAppButton
                    :href="this.getOrderUrl()"
                    app="ordering"
                    :path="mdiFood" />
                  <ViewInAppButton
                    :href="this.getKitchenUrl()"
                    app="kitchen"
                    :path="mdiToasterOven" />
                </div>
              </div>
            </div>
            <button
              type="button"
              v-if="!service.sealed"
              class="text-icon contained save-btn"
              :disabled="saving || !unsavedChanges || serviceEdit.endTime < serviceEdit.startTime"
              @click="saveService()">
              <span class="icon">
                <Icon :path="mdiCheck" :size="18" />
              </span>
              <span class="text">Update Service</span>
              <LoadingSpinner v-if="saving" />
            </button>
          </div>
        </template>
      </Card>

      <!-- <div class="active-service">
        <h4>Menu for this service:</h4>
        <p v-if="selectedMenu">{{ selectedMenu.name }}</p>
        <button @click="showModal = 'menuPicker'">Select Menu</button>
      </div> -->

      <div class="sections row baseline">
        <Card :flex="4" title="Stock Control" :tabletFull="true">
          <template v-slot:content>
            <section class="service-stock-controls">
              <div class="backdrop" v-if="unsavedChanges">
                <p>Update service to enable stock control</p>
              </div>
              <div v-else>
                <div class="control-wrapper" :class="{ grey: unsavedChanges }">
                  <div class="titles row no-gap">
                    <h5
                      :class="{ active: stockControl === 'item' }"
                      @click="this.switchStockControlView('item')">
                      Items
                    </h5>
                    <h5
                      :class="{ active: stockControl === 'component' }"
                      @click="this.switchStockControlView('component')">
                      Components
                    </h5>
                  </div>
                  <div class="mb_1">
                    <StockIconToolTip :itemOrComponent="stockControl" />
                  </div>
                  <div class="table-wrapper">
                    <StockControlItems
                      v-if="stockControl === 'item'"
                      :unsavedChanges="unsavedChanges"
                      :loadStockList="loadStockList"
                      :service="service" />
                    <StockControlComponents
                      v-if="stockControl === 'component'"
                      :unsavedChanges="unsavedChanges"
                      :loadStockList="loadStockList"
                      :service="service" />
                  </div>
                </div>
              </div>
            </section>
          </template>
        </Card>
        <!-- <Card :flex="8" title="Stock Manifest" :tabletFull="true">
          <template v-slot:content>
            <StockManifest :serviceId="service.id" />
          </template>
        </Card> -->
        <SalesOverview :service="service" />
        <TransactionsTable :page-data="service" page-data-type="service" />
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'menuPicker'"
          class="modal"
          :maxWidth="'850'"
          @close="showModal = ''">
          <template v-slot:modal-title>Select Menu</template>
          <MenuPicker
            :menusProp="menus"
            @close="showModal = ''"
            @menuChanged="menuChanged" />
        </ModalDialog>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          :maxWidth="'600'"
          :hideCloseButton="true"
          v-if="showModal === 'showAffectedOrders'">
          <template v-slot:title>Orders Affected</template>
          <ConfirmCancellation
            :affectedOrders="affectedOrders"
            @close="showModal = ''"
            @saveExistingItemConfirmed="
              userConfirmedChange = true;
              saveService();
            ">
            <template v-slot:message>
              The changes you have made will affect existing orders.
            </template>
          </ConfirmCancellation>
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { useToast } from "vue-toastification";

  // components
  import LoadingSpinner from "@/components/LoadingSpinner";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import MenuPicker from "@/components/Menus/MenuPicker.vue";
  import ConfirmCancellation from "@/components/ConfirmCancellation";
  import StockControlItems from "@/components/StockControl/StockControlItems.vue";
  import StockControlComponents from "@/components/StockControl/StockControlComponents.vue";
  import StockIconToolTip from "@/components/StockControl/StockIconToolTip.vue";
  import Picker from "@/components/Picker";
  import Card from "@/components/Card.vue";
  import BackToPageLink from "@/components/BackToPageLink.vue";
  import TransactionsTable from "@/components/TransactionsTable.vue";
  import SalesOverview from "@/components/SalesOverview.vue";
  import DetailConfigLabelAndInput from "@/components/EventDetails/DetailConfigLabelAndInput.vue";
  import ViewInAppButton from "@/components/EventDetails/ViewInAppButton.vue";
  import ServiceStatus from "@/components/EventDetails/ServiceStatus.vue";
  // import StockManifest from "@/components/StockManifest/StockManifest.vue";

  // icons
  import { mdiCheck, mdiFood, mdiToasterOven, mdiFinance } from "@mdi/js";

  // constants
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEVENDOR,
    OPERATORTYPEOUTLET,
  } from "@/constants/operatorTypes.ts";

  export default {
    components: {
      LoadingSpinner,
      ModalDialog,
      MenuPicker,
      ConfirmCancellation,
      StockControlComponents,
      StockControlItems,
      StockIconToolTip,
      Picker,
      TransactionsTable,
      Card,
      BackToPageLink,
      SalesOverview,
      DetailConfigLabelAndInput,
      ViewInAppButton,
      ServiceStatus,
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        serviceId: parseInt(this.$route.params.serviceId),
        service: null,
        serviceEdit: null,
        venue: null,
        outlet: null,
        menus: [],
        selectedMenu: null,
        unsavedChanges: false,
        showModal: "",
        selectedMenuId: null,
        serviceFromRepo: null,
        affectedOrders: [],
        saving: false,
        loadStockList: true,
        loading: true,
        userConfirmedChange: false,
        stockControl: "item",
        selectedServiceId: this.$route.params.serviceId,
        mdiCheck,
        mdiFood,
        mdiToasterOven,
        mdiFinance,
        OPERATORTYPEVENUE,
      };
    },

    computed: {
      ...mapGetters({
        apiPrivateMenusGetByOutletId: "apiPrivateMenus/getByOutletId",
        apiPrivateServicesGetById: "apiPrivateServices/getById",
        apiPrivateVenuesGetById: "apiPrivateVenues/getById",
        apiPrivateOutletsGetById: "apiPrivateOutlets/getById",
      }),

      goBackToPageData() {
        const eventId = window.localStorage.getItem("eventId");
        if (this.$route.params.operatorType === OPERATORTYPEVENUE && eventId) {
          return {
            title: "Back to Event Details",
            path: {
              name: "/event",
              params: {
                operatorType: this.operatorType,
                operatorId: this.operatorId,
                eventId: eventId,
              },
            },
          };
        } else {
          return {
            title: "Back To Dashboard",
            path: { name: "/dashboard" },
          };
        }
      },
    },

    methods: {
      showMenuPicker() {
        this.showModal = "menuPicker";
      },

      getOrderUrl() {
        return `${process.env.VUE_APP_ORDER_WEB_DOMAIN}/service/${this.selectedServiceId}`;
      },
      getKitchenUrl() {
        return `${process.env.VUE_APP_KITCHEN_WEB_DOMAIN}/service/${this.selectedServiceId}`;
      },

      viewStatement() {
        this.$router.push({
          name: "/service/statement",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            serviceId: this.serviceId,
          },
        });
      },

      saveService() {
        this.saving = true;

        if (this.serviceEdit.startTime.length <= 5) {
          this.serviceEdit.startTime += ":00";
        }

        if (this.serviceEdit.endTime.length <= 5) {
          this.serviceEdit.endTime += ":00";
        }

        const confirmationString =
          this.userConfirmedChange === true ? "affected-orders=cancel" : "";

        store.state.apiPrivate.client.endpoints.services
          .updateService(
            this.serviceEdit,
            this.selectedServiceId,
            confirmationString
          )
          .then((response) => {
            if (response.status == 200) {
              store.dispatch("apiPrivateServices/upsert", this.serviceEdit);
              this.unsavedChanges = false;
              this.userConfirmedChange = false;
              this.loadStockList = true;
              this.$emit("serviceEdited");
              useToast().success("Service Updated");
            } else if (response.status == 409) {
              this.handleSaveConflict(response.data);
            } else {
              return Promise.reject();
            }
          })
          .catch((err) => {
            useToast().error("Error saving service");
            window.log.error(err);
          });
        this.loadStockList = false;
        this.saving = false;
      },

      menuChanged(selectedMenu) {
        this.selectedMenu = selectedMenu;
        this.serviceEdit.menuId = selectedMenu.id;
        this.unsavedChanges = true;
      },

      handleSaveConflict(payload) {
        useToast().warning("Could not save Service due to existing orders.");

        this.showModal = "showAffectedOrders";
        this.affectedOrders = payload.meta["affected-orders"].$values;
      },

      switchStockControlView(viewToSwitch) {
        this.stockControl = viewToSwitch;
      },

      goToPage(path) {
        if (path.path !== "/dashboard")
          window.localStorage.removeItem("eventId");
        this.$router.push(path);
      },

      securityCheck(service) {
        if (this.operatorType == OPERATORTYPEVENUE) {
          return this.operatorId == service.venueId;
        }
        if (this.operatorType == OPERATORTYPEVENDOR) {
          return this.operatorId == service.vendorId;
        }
        if (this.operatorType == OPERATORTYPEOUTLET) {
          return this.operatorId == service.outletId;
        }

        return false;
      },
    },

    async beforeMount() {
      await this.apiPrivateServicesGetById(this.selectedServiceId).then(
        (service) => {
          if (!this.securityCheck(service)) {
            this.$router.push({ name: "/dashboard" });
            return;
          }

          this.service = service;
          this.serviceEdit = Object.assign({}, this.service);

          const venueId = this.service.venueId;
          const outletId = this.service.outletId;

          this.apiPrivateOutletsGetById(outletId).then((data) => {
            this.outlet = data;
          });

          this.apiPrivateVenuesGetById(venueId).then((data) => {
            this.venue = data;
          });

          this.apiPrivateMenusGetByOutletId(outletId).then((fullData) => {
            let data = fullData.map((x) => x.data);
            this.menus = data;

            this.selectedMenu = this.menus.find(
              (x) => x.id == this.service.menuId
            );

            this.loading = false;
          });
        }
      );
    },
  };
</script>

<style lang="scss">
  .fixed-header {
    margin-bottom: 10px;
  }

  .service-details {
    @include flex-initial($direction: column, $gap: 2rem);

    > .row {
      align-items: baseline;
      justify-content: flex-start;
      gap: 1rem;
    }
    .configs {
      flex-basis: 750px;
      align-items: baseline;
    }
    .detail-links {
      gap: 0;
      padding-top: 1rem;
      @media screen and (min-width: $small_laptop_width) {
        gap: 1rem;
        flex-basis: 200px;
        padding-top: 0rem;
      }
    }
  }

  .service-detail {
    .input-fixed {
      color: #333;
    }
  }

  .service-stock-controls {
    width: 100%;
    margin: 0;

    & > .row {
      justify-content: space-around;
      flex-wrap: nowrap;
      position: relative;

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }
    }

    .table-wrapper {
      height: 300px;
      overflow: auto;
      position: relative;
      border-top: 1px solid #ddd;
    }

    .stock-icon-info {
      padding: 0 13px;
    }

    .control-wrapper {
      mask-border: 0;
    }

    .titles {
      width: 100%;

      h5 {
        cursor: pointer;
        width: 50%;
        border-bottom: 4px solid $col_faded;
        color: #aaa;
        font-size: 1rem;
        margin: 0rem 0 1rem;
        padding: 0.8rem 0 0.5rem;
        text-align: center;

        &.active {
          border-color: $col_alpha;
          color: #000;
        }
      }
    }
  }

  .control-wrapper {
    margin-bottom: 1rem;

    h5.title {
      font-size: 1rem;
      margin: 0;
      text-transform: capitalize;
    }
  }

  .grey {
    filter: grayscale(1);
  }

  .backdrop {
    color: #aaa;
    font-size: 1rem;
  }

  .service-detail-config .save-btn {
    align-self: flex-start;
  }

  .service-status {
    @include flex-initial($gap: 0, $wrap: wrap, $alignItems: center);

    &__value {
      padding-right: 1rem;
    }

    &__note {
      font-size: 0.8rem;
      color: $col_faded-darker;

      .mdi-icon {
        display: inline-flex;
        padding: 0 0.25rem;
        position: relative;
        top: 3px;
      }
    }
  }
</style>
