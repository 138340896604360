<template>
  <div class="detail service-detail service-detail_link">
    <a :href="href" target="_blank">
      <Icon :path="path" :size="16" />{{ app }} app
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
      },
      app: {
        type: String,
      },
      path: {
        type: String,
      },
    },
  };
</script>

<style lang="scss">
  .service-detail_link {
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;

    a {
      display: flex;

      .mdi-icon {
        margin-right: 10px;
      }
    }
  }
</style>
