<template>
  <div class="service-status" v-if="1 == 2">
    <span class="service-status__value">{{
      isSealed ? "Sealed" : status
    }}</span>
    <span v-if="!isSealed" class="service-status__note">
      (The <Icon :path="mdiFinance" :size="14" />statement will be available
      once the service is sealed.)
    </span>
    <button
      v-else
      type="button"
      class="view-statement"
      :disabled="!isSealed"
      @click="$emit('viewStatement')">
      <span class="icon">
        <Icon :path="mdiFinance" :size="18" />
      </span>
      <span class="text">View Statement</span>
    </button>
  </div>
</template>
<script>
  import { mdiFinance } from "@mdi/js";
  export default {
    data() {
      return {
        mdiFinance,
      };
    },
    props: {
      status: {
        type: String,
      },
      isSealed: {
        type: Boolean,
      },
    },
    emits: ["viewStatement"],
  };
</script>

<style lang="scss" scoped>
  .service-status {
    @include flex-initial($gap: 0, $wrap: wrap, $alignItems: center);

    &__value {
      padding-right: 1rem;
    }

    &__note {
      font-size: 0.8rem;
      color: $col_faded-darker;

      .mdi-icon {
        display: inline-flex;
        padding: 0 0.25rem;
        position: relative;
        top: 3px;
      }
    }

    button.view-statement {
      @include text-button;
    }
  }
</style>
