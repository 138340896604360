<template>
  <div>
    <ag-grid-vue
      class="ag-theme-alpine alterations"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="menusProp"
      rowSelection="single"
      @rowClicked="onRowClicked">
    </ag-grid-vue>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";

  export default {
    components: {
      AgGridVue,
    },
    props: {
      menusProp: {
        default: null,
      },
    },
    emits: ["menuChanged", "close"],
    data() {
      return {
        columnDefs: null,
        defaultColDef: null,
        gridApi: null,
        gridColumnApi: null,
        selectedMenu: null,
      };
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
      },
      onRowClicked(row) {
        this.selectedMenu = row.data;
        this.$emit("menuChanged", this.selectedMenu);
        this.$emit("close");
      },
    },
    beforeMount() {
      this.defaultColDef = {
        suppressMenu: true,
        suppressMovable: true,
      };

      this.columnDefs = [
        {
          field: "name",
          filter: true,
          suppressSizeToFit: true,
          floatingFilter: true,
        },
        {
          field: "version",
          width: 100,
          cellRenderer: (params) => {
            return `v${params.value}`;
          },
        },
        {
          field: "notes",
          headerName: "Notes",
          width: 250,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return params.value || "-";
          },
        },
      ];
    },
  };
</script>
